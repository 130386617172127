import React, { useContext, useState, useEffect } from 'react';
import { useForm, Controller } from 'react-hook-form';
import ProductContext from 'context/ProductContext';
import { EmailText, PasswordText } from '../components/Recargas/input';
import { Button } from '../components/Button';
import firebase from '@firebase/app';
import { navigate } from '@reach/router';
import { Layout, Loading } from 'components';
import styled from 'styled-components';
import CartContext from '../context/CartContext';

const LoginContainer = styled.div`
  width: 100%;
  form {
    padding-top: 10%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
  }
  button {
    margin-bottom: 40px;
  }
`;

const Title = styled.div`
  font-size: 20px;
  line-height: 23px;
  text-align: center;
  font-weight: 700;
  color: #5c1565;
  margin-bottom: 5%;
`;

export const ErrorElement = styled.p`
  color: red;
  font-weight: bold;
  margin-bottom: 5px;
`;

export const StepFooter = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 10%;
  margin-bottom: 10%;
`;

export const FooterText = styled.p`
  font-size: 12px;
  line-height: 14px;
  display: flex;
  align-items: center;
  text-align: center;
  color: ${props => (props.color ? props.color : '#000000')};
  margin-top: 10px;
`;

export default function Login() {
  const { setUsuario, setPermisos, usuario, permisos, currentTranslate } = useContext(
    ProductContext
  );
  const { createCheckout } = useContext(CartContext);
  const [loading, setLoading] = useState(true);
  const { control, handleSubmit, errors } = useForm();
  const [disabled, setDisabled] = useState(false);
  const [error, setError] = useState(false);

  useEffect(() => {
    if (usuario) {
      if (permisos) {
        if (permisos.admin) {
          navigate('/admin');
          setLoading(false);
        }
      } else {
        navigate('/perfil');
        setLoading(false);
      }
    }
    setLoading(false);
  }, [usuario]);

  const onSubmit = async data => {
    setDisabled(true);
    await firebase
      .auth()
      .signInWithEmailAndPassword(data.correo, data.contraseña)
      .then(info => {
        firebase
          .auth()
          .currentUser.getIdTokenResult()
          .then(res => {
            setPermisos(res.claims);
            let col = res.claims.employee ? 'employees' : 'users';
            firebase
              .firestore()
              .collection(col)
              .where('uid', '==', info.user.uid)
              .get()
              .then(snapshot => {
                if (!snapshot.docs[0]) return;
                setError(false);
                setDisabled(false);
                setUsuario(snapshot.docs[0].data());
                if (res.claims.admin) {
                  navigate('/admin');
                } else {
                  navigate('/perfil');
                }
                createCheckout();
              }).catch(error => {
                throw error;
              });
          });
      })
      .catch(error => {
        setDisabled(false);
        setError(true);
      });
  };

  return (
    <Layout>
      {!loading ? (
        <LoginContainer>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Title>{currentTranslate.login.Login}</Title>
            <Controller
              as={<EmailText placeholder={currentTranslate.login.Email} />}
              name="correo"
              control={control}
              defaultValue=""
              rules={{
                required: true,
              }}
            />

            {errors.usuario && (
              <ErrorElement>Por favor ingresa tu usuario</ErrorElement>
            )}
            <Controller
              as={<PasswordText placeholder={currentTranslate.login.Password} />}
              name="contraseña"
              control={control}
              defaultValue=""
              rules={{
                required: true,
              }}
            />

            {errors.contraseña && (
              <ErrorElement>{currentTranslate.login.PasswordError}</ErrorElement>
            )}
            <StepFooter>
              <Button disabled={disabled}>{currentTranslate.login.Login}</Button>
              {error && (
                <ErrorElement>{currentTranslate.login.InvalidCredentials}</ErrorElement>
              )}
              <FooterText>{currentTranslate.login.NoAccount}</FooterText>
              <FooterText color=" #B73F7F">
                {currentTranslate.login.GetInTouch}
              </FooterText>
            </StepFooter>
          </form>
        </LoginContainer>
      ) : (
        <Loading />
      )}
    </Layout>
  );
}
